export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '게시글 제목',
        field: 'title',
        sort: false
    },
    {
        label: '등록 일자',
        field: 'regDate',
        sort: false
    }
];
